<style lang="scss">
.prprodukte {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
      height: 300px;
      img {
        max-height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
  }
}

.erfolgbsp {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;

    position: relative;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .inner-content {
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $blue;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $blue;
      }
    }
  }
}
.info-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 120%;
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.btn-big {
  display: block;
  margin: 10px 0;
  text-align: center;
  a {
    display: inline-block;
    padding: 15px 20px;
    background: $blue;
    color: #fff;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>

<template>
  <div class="content prprodukte container">
    <h1>Wir erzählen Ihre Geschichte!</h1>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <p class="intro-txt">Unsere Expertinnen und Experten in Sachen Content verpacken Ihre Werbebotschaften im Stil redaktioneller Berichterstattung &nbsp;&ndash;&nbsp; erklärend, informativ und spannend aufbereitet. Ob als PR-Artikel in der Zeitung, als Mitdruck oder als Journal, das der Zeitung beigelegt ist.</p>
      </div>
    </div>
    <div class="btn-std btn-bg-blue-dark">
      <router-link :to="{ name: 'Native' }" class="content-link"> Digital? Können wir! Entdecken Sie hier unsere digitalen Content-Lösungen! </router-link>
    </div>
    <div class="row margin-b-s margin-t-m">
      <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
        <h2>PR-Inserat</h2>
        <p>Präsentieren Sie Ihr Unternehmen, Ihr Produkt im Look &amp; Feel redaktioneller Berichterstattung in einem Ressort und Format Ihrer Wahl. Alle Werbeflächen in der Zeitung können als PR-Inserat gebucht werden.</p>
        <p>Die Tarife setzen sich aus dem jeweiligen Platzierungstarif und dem <a href="#produktionskostenbeitraege" class="content-link" v-smooth-scroll>PR-Produktionskostenbeitrag</a> zusammen.</p>
        <div class="btn-std btn-bg-blue-dark">
          <router-link :to="{ name: 'Platzierungen' }" class="content-link">Zu den Platzierungen</router-link>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
        <img src="@/assets/img/img-content/native/pr-hauptblatt.jpg" alt="Inserat Hauptblatt" class="img-fluid shadow img-s-75 img-center" />
      </div>
    </div>
    <div class="row margin-tb-s">
      <div class="col-lg-6 justify-content-center align-self-center">
        <h2>Mitdruck</h2>
        <p>Der Mitdruck ist Bestandteil der Kleinen Zeitung und bietet Ihnen die Möglichkeit zur großflächigen und auffälligen Darstellung Ihrer Werbebotschaft.</p>
        <div class="btn-std btn-bg-blue-dark">
          <router-link :to="{ name: 'Beilagen' }" class="content-link">Zu den Beilagen</router-link>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
        <img src="@/assets/img/img-content/native/mitdruck-lust-auf-kaernten.jpg" alt="Mitdruck" class="img-fluid shadow img-s-75 img-center" />
      </div>
    </div>
    <div class="row margin-tb-s">
      <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
        <h2>Firmenjournale und Magazine</h2>
        <p>Sie möchten Ihr eigenes Magazin oder Firmenjournal? Ob als Beilage oder als Mitdruck in der Zeitung, auf Zeitungspapier oder LWC-Papier – auf Wunsch übernehmen wir die komplette Erstellung.</p>
        <div class="btn-std btn-bg-blue-dark">
          <router-link :to="{ name: 'Beilagen' }" class="content-link">Zu den Beilagen</router-link>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
        <img src="@/assets/img/img-content/native/tj-rettl.jpg" alt="Firmenjournale und Magazine" class="img-fluid shadow img-s-75 img-center" />
      </div>
    </div>

    <div class="margin-b-s"></div>

    <div class="padding-t-s" id="produktionskostenbeitraege"></div>
    <h2>PR-Produktionskosten<span class="breaker-line">-</span><span class="breaker-mobile"></span>beiträge</h2>
    <p>Für die Erstellung von maßgeschneiderten Inhalten für Ihre Zielgruppe werden folgende Produktionskostenbeiträge in Rechnung gestellt.</p>
    <div class="row">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Produktionskostenbeitrag im Hauptblatt
          </div>
          <div class="row head-row">
            <div class="col-6 text-align-left"></div>
            <div class="col-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/1 Seite</div>
            <div class="col-6 text-align-right">225,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/2 Seite</div>
            <div class="col-6 text-align-right">125,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">bis 1/4 Seite</div>
            <div class="col-6 text-align-right">65,00</div>
          </div>
          <div class="info-txt-tabelle">Zusätzlich zum Inseratentarif. Produktionskosten sind nicht rabattfähig.</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Produktionskostenbeitrag Journale &amp; Magazine
          </div>
          <div class="row head-row">
            <div class="col-6 text-align-left"></div>
            <div class="col-6 text-align-right">Tarif pro Seite</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">ohne Fotoservice</div>
            <div class="col-6 text-align-right">225,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">inkl. Fotoservice</div>
            <div class="col-6 text-align-right">450,00</div>
          </div>
          <div class="info-txt-tabelle">Zusätzlich zu den Druck- und Beilegekosten. <router-link :to="{ name: 'Beilagen' }">Hier zu mehr Informationen. </router-link></div>
        </div>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import TabGroup from '@/components/util/TabGroup.vue';
//import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  components: {
    //TabGroup,
    //Tab,
  },
  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });
  },
});
</script>
