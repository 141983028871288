
import { defineComponent } from 'vue';
//import TabGroup from '@/components/util/TabGroup.vue';
//import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  components: {
    //TabGroup,
    //Tab,
  },
  setup() {
    document.addEventListener('scroll', () => {
      const totop = document.getElementById('backToTop');
      if (!totop) return;
      if (document.documentElement.scrollTop > 300) {
        totop.style.opacity = '1';
      } else {
        totop.style.opacity = '0';
      }
    });
  },
});
